import React, { useState } from "react";
import Layout from "../../components/Layout";
import "./style.scss";

const SchoolTourFeedbackForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    overallImpression: "",
    recommend: "",
    facilitiesCondition: "",
    classrooms: "",
    outdoorFacilities: "",
    academicInfo: "",
    curriculumQuestions: "",
    educationConfidence: "",
    staffWarmth: "",
    staffResponsiveness: "",
    admissionsClarity: "",
    likeMost: "",
    likeLeast: "",
    suggestions: "",
    additionalComments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting Form Data:", formData);

    let submitData = { ...formData };

    submitData["likeMost"] =
      formData.likeMost == "" ? "None" : formData.likeMost;
    submitData["likeLeast"] =
      formData.likeLeast == "" ? "None" : formData.likeLeast;
    submitData["suggestions"] =
      formData.suggestions == "" ? "None" : formData.suggestions;
    submitData["additionalComments"] =
      formData.additionalComments == "" ? "None" : formData.additionalComments;

    try {
      const response = await fetch(
        "https://chinmayavvdelhi.ac.in/api/public/school-feedback.php/submit",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(submitData),
        }
      );

      if (response.ok) {
        alert("Thank you for your feedback!");
        setFormData({
          firstName: "",
          lastName: "",
          overallImpression: "",
          recommend: "",
          facilitiesCondition: "",
          classrooms: "",
          outdoorFacilities: "",
          academicInfo: "",
          curriculumQuestions: "",
          educationConfidence: "",
          staffWarmth: "",
          staffResponsiveness: "",
          admissionsClarity: "",
          likeMost: "",
          likeLeast: "",
          suggestions: "",
          additionalComments: "",
        });
      } else {
        alert("Failed to submit feedback. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="parent-questionnaire">
        <h1 className="heading">School Tour Feedback Questionnaire</h1>
        <form onSubmit={handleSubmit}>
          <label>
            First Name*
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Last Name*
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </label>

          <h3>Section 1: Overall Impression</h3>

          <label>
            What was your overall impression of our school?*
            <select
              name="overallImpression"
              value={formData.overallImpression}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>

          <label>
            How likely are you to recommend our school to others?*
            <select
              name="recommend"
              value={formData.recommend}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Very Likely">Very Likely</option>
              <option value="Likely">Likely</option>
              <option value="Neutral">Neutral</option>
              <option value="Unlikely">Unlikely</option>
              <option value="Very Unlikely">Very Unlikely</option>
            </select>
          </label>

          <h3>Section 2: Facilities and Infrastructure</h3>

          <label>
            How would you rate the condition and maintenance of our facilities?*
            <select
              name="facilitiesCondition"
              value={formData.facilitiesCondition}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>

          <label>
            Were our classrooms and learning spaces well-equipped and
            organized?*
            <select
              name="classrooms"
              value={formData.classrooms}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>

          <label>
            What do you think of our outdoor play areas and sports facilities?*
            <select
              name="outdoorFacilities"
              value={formData.outdoorFacilities}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>

          <h3>Section 3: Academic Programs and Curriculum</h3>

          <label>
            How would you rate the clarity and depth of information provided
            about our academic programs?*
            <select
              name="academicInfo"
              value={formData.academicInfo}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>

          <label>
            Did you receive satisfactory answers to your questions about the
            curriculum and teaching methods?*
            <select
              name="curriculumQuestions"
              value={formData.curriculumQuestions}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>

          <label>
            How confident are you in our school's ability to provide a
            well-rounded education?*
            <select
              name="educationConfidence"
              value={formData.educationConfidence}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Very Confident">Very Confident</option>
              <option value="Confident">Confident</option>
              <option value="Neutral">Neutral</option>
              <option value="Less Confident">Less Confident</option>
              <option value="Not Confident">Not Confident</option>
            </select>
          </label>

          <h3>Section 4: Staff and Communication</h3>

          <label>
            How would you rate the warmth and welcoming nature of our staff?*
            <select
              name="staffWarmth"
              value={formData.staffWarmth}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>

          <label>
            Were your questions answered promptly and effectively by our staff?*
            <select
              name="staffResponsiveness"
              value={formData.staffResponsiveness}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>

          <label>
            How clear were our admissions and enrollment processes explained?*
            <select
              name="admissionsClarity"
              value={formData.admissionsClarity}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>

          <h3>Section 5: Additional Comments</h3>

          <label>
            What did you like the most about our school?*
            <textarea
              name="likeMost"
              value={formData.likeMost}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            What did you like the least about our school?*
            <textarea
              name="likeLeast"
              value={formData.likeLeast}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Do you have any suggestions for improvement?*
            <textarea
              name="suggestions"
              value={formData.suggestions}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Any additional comments or feedback?*
            <textarea
              name="additionalComments"
              value={formData.additionalComments}
              onChange={handleChange}
            />
          </label>

          <button type="submit">Submit Feedback</button>
          <br />
          <br />
          <small>Fields marked with an asterisk (*) are mandatory.</small>
        </form>
      </div>
    </Layout>
  );
};

export default SchoolTourFeedbackForm;
